import axios from "axios";

function addPadString(string, numtotalpad) {
    let strpad = "";
    if (string.length < 20) {
        for (let i = 0; i < numtotalpad - string.length; i++) {
            strpad += " ";
        }
        return strpad;
    } else {
        return "";
    }
}

function parseResCashier(response) {
    let parser = new DOMParser();
    const resxml = parser.parseFromString(response, "text/xml");
    let success = resxml.getElementsByTagName("response")[0].getAttribute('success');
    let res_add = resxml.getElementsByTagName("response")[0].getElementsByTagName('addInfo');
    let tag_names_array = "";
    let add_info = "";
    if (res_add.length > 0) {
        /**TODO: response della chiusura cassa
         * <response success="true" code="" status="x">
         * <addInfo>
         * <elementList>lastCommand,printerStatus,zRepNumber,dailyAmount</elementList>
         * <lastCommand>74</lastCommand>
         * <printerStatus>20110</printerStatus>
         * <zRepNumber >11</ zRepNumber>
         * <dailyAmount >332,33</ dailyAmount>
         * </addInfo>
         * </response>
         */
        let list = res_add[0].getElementsByTagName('elementList');
        let tag_names_list = list[0].childNodes[0].nodeValue;
        tag_names_array = tag_names_list.split(',');//contiene [lastCommand,printerStatus,zRepNumber,dailyAmount]
        add_info = {};

        for (let i = 0; i < tag_names_array.length; i++) {
            let node = res_add[0].getElementsByTagName(tag_names_array[i])[0];
            let node_child = node.childNodes[0];
            add_info[tag_names_array[i]] = node_child.nodeValue;
        }
        return [success, add_info];
    }
}

var TNT = 999;
var TCL = 12;
var TCONT = 0x0d; // replaced from Francesco to 0D (2014_03_09)
var TENTER = TCONT;

var tab_scan_code_lower_case = [
    TNT,
    TNT,
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8", // 00
    "9",
    "0",
    "'",
    TNT,
    TNT,
    TNT,
    "q",
    "w",
    "e",
    "r", // 10
    "t",
    "y",
    "u",
    "i",
    "o",
    "p",
    TNT,
    "*",
    TENTER,
    TNT, // 20
    "a",
    "s",
    "d",
    "f",
    "g",
    "h",
    "j",
    "k",
    "l",
    "@", // 30
    TNT,
    TNT,
    TNT,
    ">",
    "z",
    "x",
    "c",
    "v",
    "b",
    "n", // 40
    "m",
    ";",
    ".",
    "-",
    TNT,
    "*",
    TNT,
    " ",
    TNT,
    TNT, // 50
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, // 60
    TNT,
    "7",
    "8",
    "9",
    "-",
    "4",
    "5",
    "6",
    "+",
    "1", // 70
    "2",
    "3",
    "0",
    ".",
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, // 80
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TENTER,
    TNT,
    TNT,
    TNT, // 90
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //100
    TNT,
    TCL,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //110
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //120
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //130
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //140
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //150
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //160
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //170
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //180
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //190
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //200
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //210
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //220
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //230
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //240
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //250
];

var tab_scan_code_upper_case = [
    TNT,
    TNT,
    "!",
    '"',
    "#",
    "$",
    "%",
    "&",
    "/",
    "(", // 00
    ")",
    "=",
    "?",
    TNT,
    TNT,
    TNT,
    "Q",
    "W",
    "E",
    "R", // 10
    "T",
    "Y",
    "U",
    "I",
    "O",
    "P",
    TNT,
    "+",
    TENTER,
    TNT, // 20
    "A",
    "S",
    "D",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "@", // 30
    TNT,
    TNT,
    TNT,
    "<",
    "Z",
    "X",
    "C",
    "V",
    "B",
    "N", // 40
    "M",
    ",",
    ":",
    "_",
    TNT,
    "*",
    TNT,
    " ",
    TNT,
    TNT, // 50
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, // 60
    TNT,
    "7",
    "8",
    "9",
    "-",
    "4",
    "5",
    "6",
    "+",
    "1", // 70
    "2",
    "3",
    "0",
    ".",
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, // 80
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TENTER,
    TNT,
    TNT,
    TNT, // 90
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //100
    TNT,
    TCL,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //110
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //120
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //130
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //140
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //150
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //160
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //170
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //180
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //190
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //200
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //210
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //220
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //230
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //240
    TNT,
    TNT,
    TNT,
    TNT,
    TNT,
    TNT, //250
];

var stringa = "";

function filter_key(car) {
    //if (car == "\r") {
    if (car == 13) {
        console.log(stringa);
        console.log("CR");
        if (
            stringa == "=pri" ||
            stringa == "=PRI" ||
            stringa == ")pri" ||
            stringa == ")PRI"
        ) {
            doAddSellItem("Primo", 7, 1);
            stringa = "";
        }
        if (
            stringa == "=sec" ||
            stringa == "=SEC" ||
            stringa == ")sec" ||
            stringa == ")SEC"
        ) {
            doAddSellItem("Secondo", 9, 1);
            stringa = "";
        }
        if (
            stringa == "=piz" ||
            stringa == "=PIZ" ||
            stringa == ")piz" ||
            stringa == ")PIZ"
        ) {
            doAddSellItem("Pizza", 6, 1);
            stringa = "";
        }
        if (
            stringa == "=pan" ||
            stringa == "=PAN" ||
            stringa == ")pan" ||
            stringa == ")PAN"
        ) {
            doAddSellItem("Panino", 4, 1);
            stringa = "";
        }
        if (
            stringa == "=pat" ||
            stringa == "=PAT" ||
            stringa == ")pat" ||
            stringa == ")PAT"
        ) {
            doAddSellItem("Patatine", 3, 1);
            stringa = "";
        }
        if (
            stringa == "=con" ||
            stringa == "=CON" ||
            stringa == ")con" ||
            stringa == ")CON"
        ) {
            doAddSellItem("Contorno", 2, 1);
            stringa = "";
        }
        if (
            stringa == "=coc" ||
            stringa == "=COC" ||
            stringa == ")coc" ||
            stringa == ")COC"
        ) {
            doAddSellItem("Coca Cola", 2, 1);
            stringa = "";
        }
        if (
            stringa == "=caf" ||
            stringa == "=CAF" ||
            stringa == ")caf" ||
            stringa == ")CAF"
        ) {
            //  doAddSellItem('Caff&egrave;',1, 1);
            doAddSellItem("Caffè", 1, 1);
            stringa = "";
        }
        if (
            stringa == "=acq" ||
            stringa == "=ACQ" ||
            stringa == ")acq" ||
            stringa == ")ACQ"
        ) {
            doAddSellItem("Acqua", 1, 1);
            stringa = "";
        }
        var _return = stringa;
        stringa = "";
        return _return;
    } else {
        stringa = stringa + car;
        console.log(stringa);
        return "";
    }
}

export default {
    async getDataWorkspace({commit, dispatch, state}, payload = {}) {
        commit("setSpinner", true);
        axios.get("/workspace", {
            headers: {'e-token': localStorage.getItem("etokenAP")},
            params: {
                "idsalespoint": localStorage.getItem("idSalesPointAP")
            }
        }).then((response) => {
            localStorage.setItem('workspace', JSON.stringify(response.data.workspace));
            commit("setSpinner", false);
            window.location.reload();
            //window.location.reload();
        }).catch((error) => {
            commit("setSpinner", false);
            console.log(error);
        });
    },
    async getProducts({commit, dispatch, state}, payload = {}) {
        commit("setSpinner", true);
        axios.get("/products", {
            params: {
                "start": 0,
                "limit": 100,
                "idsalespoint": localStorage.getItem('idSalesPointAP'),
                "idcategory": payload.idcategory ? payload.idcategory : null,
                "description": state.searchstring ? state.searchstring : null,
            }
        }).then((response) => {
            commit('setProducts', response.data.products);
            commit("setSpinner", false);
        }).catch((error) => {
            commit("setSpinner", false);
            console.log(error);
        });
    },
    async getCategories({commit, dispatch}) {
        commit("setSpinner", true);
        commit("setCategories", []);
        commit("setIdCategory", null);
        axios.get("/categories", {
            params: {
                "start": 0,
                "limit": 100,
            }
        }).then((response) => {
            commit('setCategories', response.data.categories);
            if (response.data.categories.length) {
                commit('setIdCategory', response.data.categories[0].id);//imposto la prima categoria come quella di default
                dispatch("getProducts", {"idcategory": response.data.categories[0].id});
            }
        }).catch((error) => {
            commit("setSpinner", false);
            console.log(error);
        });
    },
    async getSales({commit, dispatch, state}) {
        commit("setSpinner", true);
        commit('setSales', []);
        axios.get("/sales", {
            params: {
                "idsalespoint": localStorage.getItem('idSalesPointAP'),
                "search": state.searchsale ? state.searchsale : null,
            }
        }).then((response) => {
            commit('setSales', response.data.sales);
            commit('setTotalSales', response.data.totalsales);
            commit("setSpinner", false);
        }).catch((error) => {
            commit("setSpinner", false);
            console.log(error);
        });
    },
    async sendSale({commit, dispatch, state}, payload) {
        axios.post("/dosale", {
            "idCustomer": payload.idCustomer,
            "datetime" : payload.datetime,
            "timestamp": payload.timestamp,
            "payment": payload.payment,
            "total": payload.totale,
            "discount": payload.sconto,
            "change": payload.resto,
            "idsalespoint": localStorage.getItem('idSalesPointAP'),
            "products": state.prodsReceipt,
            "manageStock": payload.manageStock ? "1" : "0",
            "fiscalReceiptNumber": payload.fiscalReceiptNumber,
            "zRepNumber": payload.zRepNumber,
            "idCashier": localStorage.getItem('idCashierAP'),
            "type": payload.type
        }, {}).then(response => {
            let totalf = Number(state.total).toFixed(2).replace(".", ",");
            let changef = Number(state.change).toFixed(2).replace(".", ",");
            //format total and resto on display
            let firstLine = `TOTALE${totalf} EURO`;
            let secondLine = `RESTO${changef} EURO`;

            let strpadfl = addPadString(firstLine, 20);
            let strpadsl = addPadString(secondLine, 20);

            let totalsDisplay = `<printerCommand><displayText operator="1" data="TOTALE${strpadfl}${totalf} EURORESTO${strpadsl}${changef} EURO" /></printerCommand>`;
            dispatch("sendData", {xml: totalsDisplay});
            commit('emptyReceipt');
        }).catch((error) => {
            console.log(error);
        });
    },
    //storno
    async refundSale({commit, dispatch}, payload) {
        axios.delete("/sales", {
            data: {
                "idsale": payload,
            }
        }).then((response) => {
            console.log("Lo scontrino con ID: " + payload + " è stato annullato.");
        }).catch((error) => {
            console.log(error);
        });
    },
    //reso
    async doRefund({commit, dispatch}, payload) {
        axios.post("/dorefund", {
            "idsale": this.getters.getIdSale,
            "prodsRefund": this.getters.getProdsReceipt,
        }, {}).then((response) => {
        }).catch((error) => {
            console.log(error);
        });
    },
    //cambio
    async doChange({commit, dispatch}, payload) {
        //const timestamp = Math.floor(new Date().getTime() / 1000).toString();
        console.log("payload", payload);
        axios.post("/dochange", {
            "idCustomer": payload.idCustomer,
            "idsale": this.getters.getIdSale,
            "datetime" : payload.datetime,
            "timestamp": payload.timestamp.toString(),
            "payment": payload.payment,
            "total": payload.totale,
            "discount": payload.sconto,
            "change": payload.resto,
            "idsalespoint": localStorage.getItem('idSalesPointAP'),
            "products": this.getters.getProdsReceipt,
            "manageStock": payload.manageStock ? "1" : "0",
            "fiscalReceiptNumber": payload.fiscalReceiptNumber,
            "zRepNumber": payload.zRepNumber,
            "idCashier": localStorage.getItem('idCashierAP')
        }, {}).then((response) => {
            commit('emptyReceipt');
        }).catch((error) => {
            console.log(error);
        });
    },
    async getCustomers({commit, dispatch}, payload = {}) {
        commit("setSpinner", true);
        commit('setCustomers', []);
        axios.get("/customers", {
            params: {
                "idsalespoint": localStorage.getItem('idSalesPointAP'),
                "search": payload.search ? payload.search : null,
                "start": 0,
                "limit": 100,
            }
        }).then((response) => {
            commit('setCustomers', response.data.customers);
            commit("setSpinner", false);
        }).catch((error) => {
            commit("setSpinner", false);
            console.log(error);
        });
    },
    async getTransfers({commit, dispatch}, payload = {}) {
        commit("setSpinner", true);
        commit('setTransfers', []);
        axios.get("/transfers", {
            params: {
                "idsalespoint": localStorage.getItem('idSalesPointAP'),
                "search": payload.search ? payload.search : null,
            }
        }).then((response) => {
            commit('setTransfers', response.data.transfers);
            commit("setSpinner", false);
        }).catch((error) => {
            commit("setSpinner", false);
            console.log(error);
        });
    },
    async commitTransfer({commit, dispatch}, payload) {
        axios.post("/transfers/commit", {}, {}).then((response) => {
        }).catch((error) => {
            console.log(error);
        });
    },

    addProduct({state}, payload = {}) {
        let prodIndex = state.prodsReceipt.findIndex(prod => prod.id === payload.id);
        //se idvariant che passo è null
        if (payload.idvariant === null) {
            //filtro i prodotti nello scontrino tramite id
            let check = state.prodsReceipt.filter(prod => prod.id === payload.id);
            if (check.length === 0) {
                //prodotto non trovato, quindi lo aggiungo sia se con variante sia senza
                state.prodsReceipt.push(payload);
            } else {
                state.prodsReceipt[prodIndex].qnt += payload.qnt;
            }
        } else if (payload.idvariant) {
            //se l'idvariant è presente filtro i prodotti tramite idvariant
            let varIndex = state.prodsReceipt.findIndex(prod => prod.idvariant === payload.idvariant);
            if (varIndex >= 0) {
                state.prodsReceipt[varIndex].qnt += payload.qnt;
            } else {
                //aggiungo nuova variante
                state.prodsReceipt.push(payload);
            }
        }
    },
    calculateTotal({state}, payload = {}) {
        let total = 0;
        state.prodsReceipt.forEach(p => {
            p.discountprice ? total += parseFloat(p.discountprice * p.qnt) : total += parseFloat(p.price * p.qnt);
        });
        state.total = total.toFixed(2);
    },
    async sendData({commit, dispatch, state}, payload) {
        if (payload.hidespinner) {
            commit("setSpinner", false);
        } else {
            commit("setSpinner", true);
        }
        // create SOAP envelope
        //RCH PRINTF
        let soap = '<?xml version="1.0" encoding="utf-8"?><s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/"><s:Body>' + payload.xml + '</s:Body></s:Envelope>';
        axios.post("http://" + localStorage.getItem("ipCashierAP") + "/cgi-bin/fpmate.cgi?timeout=10000", soap, {
            headers: {
                "e-token": null,
                "Content-Type": "text/xml; charset=UTF-8",
                "If-Modified-Since": "Thu, 01 Jan 1970 00:00:00 GMT",
                "SOAPAction": '""'
            },
            timeout: 10000
        }).then(response => {
            commit("setSpinner", false);
            let res = parseResCashier(response.data);//mi ritorna un array con success e add_info
            //let code = resxml.getElementsByTagName("response")[0].getAttribute('code');
            //let status = resxml.getElementsByTagName("response")[0].getAttribute('status');
            //res contiene success e add_info
            if (res[0] === "true") {
                if (payload.type === "sale") {
                    //converto data e ora dello scontrino
                    let datetime = null;
                    if(res[1].fiscalReceiptDate !== undefined && res[1].fiscalReceiptTime !== undefined){
                        let dateTimeString = res[1].fiscalReceiptDate + " " + res[1].fiscalReceiptTime;
                        // Dividi la stringa in data e orario
                        let [data, ora] = dateTimeString.split(' ');

                        // Ulteriore divisione per giorno, mese, anno
                        let [giorno, mese, anno] = data.split('/').map(num => num.padStart(2, '0'));

                        // Divisione e formattazione dell'orario
                        let [ore, minuti] = ora.split(':').map(num => num.padStart(2, '0'));

                        // Composizione della data e orario nel formato desiderato
                        datetime = `${giorno}/${mese}/${anno} ${ore}:${minuti}`;
                    }
                    //effettuo la vendita, il reso o il cambio
                    let sale = {
                        "idCustomer" : state.idCustomer,
                        "datetime" : datetime,
                        "timestamp": payload.timestamp,
                        "manageStock": payload.manageStock,
                        "payment": payload.selectedPayment,
                        "totale": state.total ? state.total.toString() : state.total.toString(),
                        "sconto": payload.discount ? payload.discount.toString() : "0",
                        "resto": payload.change ? payload.change.toString() : "0",
                        "fiscalReceiptNumber": res[1].fiscalReceiptNumber || "",
                        "zRepNumber": res[1].zRepNumber || "",
                        "type": state.docHeading.company ? "invoice" : "receipt",
                        "products": payload.products
                    };

                    if (state.exchange) {
                        dispatch("doChange", sale);
                        commit('setExChange', false);
                    } else {
                        dispatch("sendSale", sale);
                    }
                } else if (payload.type === "refund") {
                    //ritorno nello stato a 0
                    let xml = '<printerCommand><clearText operator="1" /><resetPrinter operator="1" /></printerCommand>';
                    dispatch("sendData", {xml: xml});
                    dispatch("doRefund", {
                        "idsale": payload.idsale,
                    });
                    commit('setStatusRefund', false);
                    commit("setSellsAlert", false);
                    commit('emptyReceipt');
                } else if (payload.type === "storno") {
                    dispatch("getSales");
                } else if (payload.type === "closeCashier") {
                    dispatch("closeCashier", res[1]);
                }
                //chiudo la modale dello scontrino
                commit("setReceiptAlert", false);
                commit('setDocHeading', {});
                commit('setInvoice', false);
            } else {
                alert("Attenzione: l'operazione non è andata a buon fine.");
            }
        }).catch(error => {
            commit("setSpinner", false);
            alert("Attenzione: controllare la connessione con la stampante fiscale.");
            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out');
            } else {
                console.log(error.message);
            }
        });
    },
    async closeCashier({commit}, payload) {
        /**TODO: response della chiusura cassa
         * <response success="true" code="" status="x">
         * <addInfo>
         * <elementList>lastCommand,printerStatus,zRepNumber,dailyAmount</elementList>
         * <lastCommand>74</lastCommand>
         * <printerStatus>20110</printerStatus>
         * <zRepNumber >11</ zRepNumber>
         * <dailyAmount >332,33</ dailyAmount>
         * </addInfo>
         * </response>
         */
        axios.post("/closecashier", {
            zRepNumber: payload.zRepNumber.toString(),
            dailyAmount: payload.dailyAmount.toString(),
            idSalesPoint: localStorage.getItem("idSalesPointAP"),
            idCashier: localStorage.getItem("idCashierAP")
        }).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });
    },
    async createDeviceScanner({commit, dispatch, state}, payload) {
        try {
            let url = 'ws://' + localStorage.getItem("ipCashierAP") + ':8008/connect';
            state.socket = new WebSocket(url);
            state.socket.onopen = function (ev) {
                console.log('CONNECTED');
                state.sockconn = true;
            };
            state.socket.onclose = function (ev) {
                console.log('DISCONNECTED');
                state.sockconn = false;
            };
            state.socket.onerror = function (ev) {
                console.log('ERROR');
                state.sockconn = false;
            };
            state.socket.onmessage = function (event) {
                console.log('MESSAGE');
                let data = JSON.parse(event.data);
                let header = data[0];
                switch (header) {
                    case "open_device":
                        //il dispositivo è connesso
                        if (data[2] === "OK") {
                            //device connesso
                            commit("setDeviceConn", true);
                            console.log("DEVICE CONNESSO");
                        } else {
                            commit("setDeviceConn", false);
                            console.log("DEVICE NON CONNESSO");
                        }
                        break;
                    case "close_device":
                        console.log(data);
                        break;
                    case "device_data":
                        let car;
                        let stream = data[3];
                        if (isNaN(stream.data4)) {
                            console.log("Data not a number");
                        } else {
                            if (stream.data2 === 1) {
                                car = tab_scan_code_upper_case[stream.data4];
                                console.log("Shift pressed; car = " + car);
                            } else {
                                car = tab_scan_code_lower_case[stream.data4];
                                console.log("Shift not pressed; car = " + car);
                            }
                            let string_from_scanner = filter_key(car);
                            if (string_from_scanner !== "") {
                                if (string_from_scanner.length !== 10) {
                                    state.searchstring = string_from_scanner;
                                    dispatch("getProducts");
                                } else {
                                    //timestamp
                                    state.searchsale = string_from_scanner;
                                    commit("setSellsAlert", true);
                                    dispatch("getSales");
                                }
                            }
                        }
                        break;
                    case "error":
                        console.log("ERROR: " + data);
                        break;
                    default:
                        break
                }
            };
        } catch (error) {
            console.log(error.message);
        }
    },
    async sendDataScanner({commit, dispatch, state}) {
        try {
            state.socket.send(JSON.stringify([
                "open_device",
                "local_scanner",
                {"type": "type_scanner", "crypto": false}
            ]));
        } catch (error) {
            console.log(error.message);
        }
    },
    async closeSocket({state}, payload) {
        state.socket.close();
        state.sockconn = false;
    },
    setQntReceipt({state, dispatch, commit}, payload) {
        switch (payload.action) {
            case "+" : {
                if (state.exchange) {
                    //se è un cambio
                    //se change è a true vedo lo stock altrimenti la qnt
                    if (state.prodsReceipt[payload.index]["change"] &&
                        state.prodsReceipt[payload.index]["qnt"] + 1 > state.prodsReceipt[payload.index].stock) {
                        commit("setShowAlertStock", true);
                        commit('setMessage', "Il prodotto non ha disponibilità a magazzino.");
                    } else if (!state.prodsReceipt[payload.index]["change"] &&
                        state.prodsReceipt[payload.index]["qnt"] + 1 > state.prodsReceipt[payload.index].beforeqnt) {
                        commit("setShowAlertStock", true);
                        commit('setMessage', "Il prodotto non ha disponibilità a magazzino.");
                    } else {
                        state.prodsReceipt[payload.index]["qnt"] += 1;
                        commit("setShowAlertStock", false);
                    }
                } else {
                    //non è un cambio quindi vedo solo la quantità in base allo stock dentro prodsReceipt
                    //controllo lo stock se è > 0, se true allora  controllo che la quantità non superi le giacenze
                    if (state.prodsReceipt[payload.index].stock > 0) {
                        if (state.prodsReceipt[payload.index]["qnt"] + 1 > state.prodsReceipt[payload.index].stock) {
                            commit("setShowAlertStock", true);
                            commit('setMessage', "Il prodotto non ha disponibilità a magazzino.");
                        }
                    } else {
                        //altrimenti se lo stock è = o < 0 aggiungo la quantità a piacimento
                        state.prodsReceipt[payload.index]["qnt"] += 1;
                        commit("setShowAlertStock", false);
                    }
                }
            }
                break;
            case "-" : {
                if (state.prodsReceipt[payload.index]["qnt"] - 1) {
                    state.prodsReceipt[payload.index]["qnt"] -= 1;
                }
            }
                break;
        }
        //stampa su display il nome e il prezzo del prodotto
        let product = state.prodsReceipt[payload.index];
        let prodname = state.prodsReceipt[payload.index].description;
        let secondLine = `${Number(product.price * product.qnt).toFixed(2).toString().replace(".", ",")} EURO`;
        let strpad = "";
        let numtotalpad = 20;
        let max_item_desc_len = 17;
        if (secondLine.length < 20) {
            for (let i = 0; i < numtotalpad - secondLine.length; i++) {
                strpad += " ";
            }
        } else {
            strpad = "";
        }
        if (prodname.length < max_item_desc_len) {
            //forzare la lunghezza di max_item_desc_len mettendo gli spazi se necessario
            prodname = prodname + "                 "; // lunghezza massima della stampante è 17 caratteri senza la quantità
            prodname = prodname.substring(0, max_item_desc_len);
        } else {
            prodname = prodname.slice(0, max_item_desc_len); // Troncare la stringa
        }
        let messageDisplay = `<printerCommand><displayText operator="1" data="x${product.qnt} ${prodname}${strpad}${secondLine}" /></printerCommand>`;
        dispatch("sendData", {xml: messageDisplay, hidespinner: true});//metto a true perché non mi devo aspettare results dalla stampante
        dispatch("calculateTotal");
    }
}