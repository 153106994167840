<template>
  <div class="modal" :class="{show : showAlertReceipt}">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Stampa documento</h5>
          <button v-on:click="toggleModal()" type="button" class="btn-close"/>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6">
              <div class="row">
                <div class="col-lg-12">
                  <h5 class="title-modal">Tipo pagamento </h5>
                  <div class="ck-button">
                    <label>
                      <input type="radio" value="carta" v-model="selectedPayment" name="payment"
                             v-on:click="paymentType('carta')"><span>Carta</span>
                    </label>
                  </div>
                  <div class="ck-button">
                    <label>
                      <input type="radio" value="bancomat" v-model="selectedPayment" name="payment"
                             v-on:click="paymentType('bancomat')"><span>Bancomat</span>
                    </label>
                  </div>
                  <div class="ck-button">
                    <label>
                      <input type="radio" value="contanti" v-model="selectedPayment" id="contanti" name="payment"
                             v-on:click="paymentType('contanti')"><span>Contanti</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <h5 class="title-modal">Sconto</h5>
                  <div class="form-check form-check-inline mb-2">
                    <input class="form-check-input" id="amount" checked="checked" name="typeDiscount" type="radio" value="amount" v-model="selOptionDiscount">
                    <label class="form-check-label" for="amount">
                      Importo
                    </label>
                  </div>
                  <div class="form-check form-check-inline mb-2">
                    <input class="form-check-input" id="percentage" name="typeDiscount" type="radio" value="percentage" v-model="selOptionDiscount">
                    <label class="form-check-label" for="percentage">
                      Percentule
                    </label>
                  </div>
                  <div class="input-group mt-3 mb-3">
                    <input v-model="discount" type="text" class="form-control" placeholder="Inserisci sconto">
                    <div class="input-group-append">
                      <button class="btn btn-outline-danger" type="button" v-on:click="resetDiscount()">
                        Reset
                      </button>
                    </div>
                    <div class="input-group-append">
                      <button class="btn btn-dark" type="button" v-on:click="applyDiscount()">
                        Applica
                      </button>
                    </div>
                  </div>
                  <h5 class="title-modal">Voucher</h5>
                  <div class="input-group mt-3 mb-3">
                    <input v-model="inputVoucher" type="text" class="form-control" placeholder="Inserisci voucher">
                    <div class="input-group-append">
                      <button class="btn btn-outline-danger" type="button" v-on:click="resetDiscount()">
                        Reset
                      </button>
                    </div>
                    <div class="input-group-append">
                      <button class="btn btn-dark" type="button" :disabled="disableApply" v-on:click="applyVoucher()">
                        Applica
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <h5 class="title-modal">Totali</h5>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-check mb-2">
                    <input class="form-check-input" value="on" name="manageStock" type="checkbox" id="manageStock"
                           v-model="manageStock" checked="checked">
                    <label class="form-check-label" v-on:click="scaricaMagazzino()" for="manageStock">
                      Scarica magazzino
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <table class="table">
                  <tr>
                    <th class="text-start">
                      Sconto
                    </th>
                    <th class="text-end">
                      <span id="tot_mod">{{ this.discount ? formatPrice(this.discount) : formatPrice(this.discount) || '€' }} €</span>
                    </th>
                  </tr>
                  <tr>
                    <th class="text-start">
                      Resto
                    </th>
                    <th class="text-end">
                      <span id="change">{{ formatPrice(this.change) + ' €' || '0 €' }}</span>
                    </th>
                  </tr>
                  <tr>
                    <th class="text-start totalbold">
                      Totale
                    </th>
                    <th class="text-end totalbold">
                      <span id="tot_mod">{{ this.total ? formatPrice(this.total) : formatPrice(this.total) || '€' }} €</span>
                    </th>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="keyboard">
                <div class="input-group mb-3">
                  <input type="text" class="output form-control text-end" :value="result" readonly>
                </div>
                <div class="btn-group-vertical">
                  <div class="btn-group">
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(7)">7</button>
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(8)">8</button>
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(9)">9</button>
                  </div>
                  <div class="btn-group">
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(4)">4</button>
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(5)">5</button>
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(6)">6</button>
                  </div>
                  <div class="btn-group">
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(1)">1</button>
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(2)">2</button>
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(3)">3</button>
                  </div>
                  <div class="btn-group">
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput('.')">.</button>
                    <button class="btn btn-light btn-calc" type="button" v-on:click="addToOutput(0)">0</button>
                    <button class="btn btn-canc btn-light btn-calc" type="button" v-on:click="clearResult()">C</button>
                  </div>
                </div>
              </div>
              <div class="bottomcalc">
                <button :class="{disabled : !cashesActive}" class="btn btn-success resto"
                        v-on:click="calculateChange()">Calcola resto
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <div class="box-button">
            <button class="btn btn-outline-dark" type="button" v-on:click="toggleModal()">
              Annulla
            </button>
          </div>
          <div class="box-button">
            <button class="btn btn-success me-2" type="button" :class="{disabled : this.invoice}" v-on:click="printBill()">
              Conto
            </button>
            <button class="btn btn-success" type="button" v-on:click="handleReceipt()">
              Stampa
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button v-on:click="toggleModal()" class="scontrino float-start btn btn-large btn-dark">Scontrino</button>
</template>

<script>
import axios from "axios";

export default {
  name: "ReceiptAlert",
  data() {
    return {
      manageStock: true,
      result: 0, //tastierino
      selectedPayment: "carta",
      selOptionDiscount: "amount",
      discount: "",
      voucher: "",
      cashesActive: false,
      resultsPos: null,
      timestamp: null,
      inputVoucher: "",
      voucherDiscount: 0,
      voucherCode: "",
      disableApply: false,
      data_to_send: null
    }
  },
  computed: {
    total() {
      return this.$store.getters.getTotal;
    },
    change() {
      return this.$store.getters.getChange;
    },
    exchange() {
      return this.$store.getters.getExChange;
    },
    prodsReceipt() {
      return this.$store.getters.getProdsReceipt;
    },
    invoice() {
      return this.$store.getters.getInvoice;
    },
    docHeading() {
      return this.$store.getters.getDocHeading;
    },
    showAlertReceipt() {
      return this.$store.getters.getAlertReceipt;
    },
  },
  methods: {
    printBill() {
      const timestamp = Math.floor(new Date().getTime() / 1000);
      if (this.prodsReceipt.length) {
        this.open = false;
        const receipt = this.$store.getters.getProdsReceipt;
        this.data_to_send = '<printerNonFiscal><beginNonFiscal operator="1" />';
        this.data_to_send += `<printNormal operator="1"  data="                                        EURO" justification="1" />`;
        for (let i = 0; i < receipt.length; i++) {
          //per la tax
          let dep = localStorage.getItem("dep" + receipt[i].tax);
          if (dep) {
            if (receipt[i].price >= 0) {
              //formattazione del nome del prodotto
              this.splitDescription(receipt[i].description,receipt[i].qnt,receipt[i].price);
            }
          } else {
            alert("Attenzione: prodotto con reparto non mappato.");
          }
        }
        //formattazione di sconto e totale
        this.formatDiscountAndTotal();

        this.data_to_send += '<endNonFiscal operator="1" /></printerNonFiscal>';

        this.$store.dispatch("sendData", {
          xml: this.data_to_send,
          type: "sale",
          timestamp: timestamp,
          manageStock: this.manageStock,
          selectedPayment: this.selectedPayment,
          discount: this.discount,
          change: this.change
        });
      }
    },
    printReceipt(timestamp) {
      if (this.prodsReceipt.length) {
        this.open = false;
        const receipt = this.$store.getters.getProdsReceipt;
        console.log("receipt",receipt);
        if (this.invoice) {
          this.data_to_send = `<printerFiscalDocument>
              <printRecMessage operator="1" message="${this.docHeading.company}" messageType="6" index="1" font="1" />
              <printRecMessage operator="1" message="P.IVA: ${this.docHeading.vatid}    SDI: ${this.docHeading.sdicode}" messageType="6" index="2" font="1" />
              <printRecMessage operator="1" message="${this.docHeading.address}" messageType="6" index="3" font="1" />
             <printRecMessage operator="1" message="${this.docHeading.zipcode}, ${this.docHeading.city} (${this.docHeading.prov}), ${this.docHeading.country}" messageType="6" index="4" font="1" />
             <printRecMessage operator="1" message="${this.docHeading.pec}" messageType="6" index="5" font="1" />
             <beginFiscalDocument operator="1" documentType="directInvoice" documentNumber="0">`; //mettiamo 0 perchè assumiamo che la stampante gestisce il numero di fattura
        } else {
          this.data_to_send = '<printerFiscalReceipt><beginFiscalReceipt operator="1" />'
        }
        for (let i = 0; i < receipt.length; i++) {
          //per la tax
          let dep = localStorage.getItem("dep" + receipt[i].tax);
          if (dep) {
            if(receipt[i].price >= 0) {
              this.data_to_send += `<printRecItem operator="1" description="${receipt[i].description}" quantity="${receipt[i].qnt}" unitPrice="${receipt[i].price}" department="${dep}" justification="1" />`;
            } else {
              //se c'é un prodotto negativo allora è un cambio
              this.$store.commit("setExChange", true);
              this.data_to_send += `<printRecItemVoid operator="1" description="${receipt[i].description}" quantity="${receipt[i].qnt}" unitPrice="${receipt[i].price * -1}" department="${dep}" justification="1" />`;
            }
          } else {
            alert("Attenzione: prodotto con reparto non mappato.");
          }
        }

        if (this.total < 0) {
          let depVoucher = localStorage.getItem("voucher");
          this.$store.commit('setTotal', 0);
          this.data_to_send += `<printRecItem operator="1" description="VOUCHER ${this.voucherCode}" quantity="1" unitPrice="${this.voucherDiscount}" department="${depVoucher}" justification="1" />`;
        }

        if (this.discount) {
          this.data_to_send += `<printRecSubtotalAdjustment operator="1" description="Sconto" adjustmentType="1" amount="${this.discount}" justification="2" />`;
        } else {
          this.data_to_send += `<printRecSubtotal operator="1" option="1" />`;
        }

        switch (this.selectedPayment) {
          case "carta":
            this.data_to_send += `<printRecTotal operator="1" description="CARTA" payment="${this.total}" paymentType="2" index="1" justification="1" />`;
            break;
          case "bancomat":
            this.data_to_send += `<printRecTotal operator="1" description="BANCOMAT" payment="${this.total}" paymentType="2" index="1" justification="1" />`;
            break;
          case "contanti":
            this.data_to_send += `<printRecTotal operator="1" description="CONTANTI" payment="${parseFloat(this.total) + parseFloat(this.change)}" paymentType="0" index="1" justification="1" />`;
            break;
          default:
            break;
        }
        if (this.invoice) {
          this.data_to_send += `<printNormal data="" font="1" operator="1" />
                      <printBarCode operator="1" position="901" width="2" height="66" hRIPosition="3" hRIFont="C" codeType="CODE39" code="${timestamp}" />
                     <endFiscalDocument operator="1" />
                </printerFiscalDocument>`;
        } else {
          this.data_to_send += `<printNormal operator="1" font="1" data="" />
                     <printRecMessage  operator="1" messageType="3" index="3" font="4" message="Arrivederci e grazie" />
                     <printNormal operator="1" font="1" data="" />
                     <printBarCode operator="1" position="901" width="2" height="66" hRIPosition="3" hRIFont="C" codeType="CODE39" code="${timestamp}" />
                     <endFiscalReceipt operator="1" />
                     </printerFiscalReceipt>`;
        }
        console.log("this.prodsReceipt",this.prodsReceipt)
        this.$store.dispatch("sendData", {
          xml: this.data_to_send,
          type: "sale",
          timestamp: timestamp,
          manageStock: this.manageStock,
          selectedPayment: this.selectedPayment,
          discount: this.discount,
          change: this.change,
        });
        this.discount = "";
        this.result = 0;
      }
    },
    resetDiscount() {
      this.inputVoucher = "";
      this.disableApply = false;
      this.discount = "";
      this.$store.dispatch("calculateTotal");
    },
    //in base alla modalità di sconto selezionata calcolo il nuovo totale
    applyDiscount() {
      if (this.selOptionDiscount && this.discount) {
        let newtotal = 0;
        if (this.selOptionDiscount === "amount") {
          newtotal = parseFloat(this.total) - parseFloat(this.discount);
          if(newtotal >= 0){
            this.$store.commit("setTotal", newtotal);
          }else{
            this.$store.commit("setTotal", 0);
          }
        } else if (this.selOptionDiscount === "percentage") {
          newtotal = parseFloat(this.total) * (1 - parseFloat(this.discount) / 100);
          if(newtotal >= 0){
            this.$store.commit("setTotal", newtotal);
            this.discount = (this.total / 100) * this.discount;
          }else{
            this.$store.commit("setTotal", 0);
          }
        }
      }
    },
    applyVoucher() {
      //couponCode
      axios.post("/coupons/check", {
        "couponCode": this.inputVoucher,
      }).then((res) => {
        let newtotal = 0;
        if (res.data.type === "amount") {
          newtotal = this.convertFloat(this.total) - this.convertFloat(res.data.value);
          if(newtotal >= 0) {
            this.$store.commit("setTotal", newtotal);
            this.discount = this.convertFloat(res.data.value);
          }else{
            this.discount = this.convertFloat(this.total);
            this.$store.commit("setTotal", 0);
          }
          this.disableApply = true;
        }else if(res.data.type === "percentage"){
          newtotal = this.convertFloat(this.total) * (1 - this.convertFloat(res.data.value) / 100);
          if(newtotal >= 0) {
            this.discount = (this.total / 100) * res.data.value;
            this.$store.commit("setTotal", this.roundFloat(newtotal));//arrotondo il nuovo totale
            this.disableApply = true;
          }else{
            this.inputVoucher = "";
            alert("Il voucher inserito non può essere applicato al totale dello scontrino.");
          }
        }
      }).catch((error) => {
        if (error.response.status === 400) {
          alert("Attenzione! La richiesta non è formattata nel modo corretto.")
        } else if (error.response.status === 404) {
          alert("Attenzione! Il voucher non esiste.")
        } else {
          alert(error.response.data.error);
        }
      });
    },
    scaricaMagazzino() {
      this.manageStock = !this.manageStock;
    },
    //se clicco contanti abilito il calcola resto altrimenti resta disattivato
    paymentType(type) {
      this.selectedPayment = type;
      if (type === "contanti") {
        this.cashesActive = true;
      } else {
        this.cashesActive = false;
      }
    },
    //apertura/chiusura modale
    toggleModal() {
      this.$store.commit("setReceiptAlert", !this.showAlertReceipt);
      this.showAlertReceipt = !this.open;
      this.$store.commit("setInvoice", false);
      this.$store.commit("setDocHeading", {});
      this.resetDiscount();
    },
    //tastierino
    addToOutput(value) {
      if (this.result === 0) {
        if (value !== 0) {
          this.result = value.toString();
        }
      } else {
        if (this.result.length < 12) {
          this.result += value.toString();
        }
      }
    },
    //resetta il tastierino
    clearResult() {
      this.$store.dispatch("calculateTotal");
      this.$store.commit("setChange", 0);
      this.discount = null;
      this.result = 0;//reset display del tastierino
    },
    calculateChange() {
      //se il totale e il result è maggiore di 0
      if (this.total && this.result && this.result >= this.total) {
        this.$store.commit('setChange', this.result - this.total);
      }
    },
    roundFloat(value){
      return Number(value.toFixed(2));
    },
    convertFloat(num) {
      return parseFloat(num.toString().replace(",", "."));
    },
    formatPrice(value) {
      return Number(value)
          .toFixed(2)
          .replace(".", ",");
    },
    handleReceipt() {
      const timestamp = Math.floor(new Date().getTime() / 1000);
      if (this.total < 0) {
        this.voucherDiscount = this.total * -1;
        axios.post("/coupons/generate", {
          "type": "amount",
          "value": this.voucherDiscount,
          "timestampSale": timestamp
        }).then((res) => {
          this.voucherCode = res.data.coupon.couponcode;
          this.printReceipt(timestamp);
        }).catch((error) => {
          if (error.code === "400") {
            alert("Attenzione! La richiesta non è formattata nel modo corretto.")
          } else {
            alert(error.message);
          }
        });
      } else {
        this.printReceipt(timestamp);
      }
    },
    splitDescription(description, qnt, price) {
      //TODO: non modificare i numeri altrimenti sballa la formattazione dello scontrino
      let descriptionPart1 = "";
      let descriptionPart2 = "";
      // Se la lunghezza del nome del prodotto è maggiore o uguale a 30, costruisci due variabili e spezza il nome cosi da andare a capo
      if (description.length >= 30) {
        const maxLength = 30;
        descriptionPart1 = description.slice(0, maxLength);
        descriptionPart2 = description.slice(maxLength);
      } else {
        descriptionPart1 = description;
      }
      const spacesBetweenDescAndPrice = 34 - descriptionPart1.length; // Calcola il numero di spazi rimanenti
      // Calcola il numero di spazi tra la quantità e il prezzo per allineare il prezzo a destra
      const maxPriceWidth = 7; // Larghezza massima del campo prezzo
      const priceFormat = this.formatPrice(price);
      const spacesBetweenQntAndPrice = maxPriceWidth - priceFormat.length - qnt.toString().length;

      this.data_to_send += `<printNormal operator="1" data="${descriptionPart1} ${" ".repeat(spacesBetweenDescAndPrice)}X ${qnt}  ${" ".repeat(spacesBetweenQntAndPrice)}${priceFormat}" justification="1" />`;

      if (descriptionPart2) {
        this.data_to_send += `<printNormal operator="1" data="${descriptionPart2}" justification="1" />`;
      }
    },

    formatDiscountAndTotal(){
      if (this.discount) {
        const spaces = 34 - this.discount.length; // Calcola il numero di spazi rimanenti
        this.data_to_send += `<printNormal operator="1"  data="Sconto ${" ".repeat(spaces)} ${this.formatPrice(this.discount)}" justification="2" />`;
      } else {
        this.data_to_send += `<printNormal operator="1" option="1" />`;
      }
      const spaces= 37 - this.formatPrice(this.total).length; // Calcola il numero di spazi rimanenti
      this.data_to_send += `<printNormal operator="1" font="4" data="TOTALE ${" ".repeat(spaces)} ${this.formatPrice(this.total)}" justification="1" />`;
    }
  },
}
</script>

<style scoped>

.keyboard {
  border: 1px solid #dedede;
}

.keyboard .output {
  border: none;
  border-bottom: 1px solid #dedede;
}

.btn-canc {
  color: red;
}

.btn-calc {
  width: 100%;
  font-size: 45px;
  font-weight: bold;
}

.modal-dialog {
  background-color: white;
  z-index: 1;

}

.modal:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

.scontrino {
  width: 100%;
  border-radius: 0px;
}

.title-modal {
  text-align: left;
  padding: 10px 0 10px 0;
  font-size: 15px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 20px;
}

label, h4 {
  float: left;
}

input, button {
  border-radius: 0px;
}

.resto {
  width: 100%;
}

.keyboard {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.output {
  font-size: 40px;
  align-items: flex-end;
}

</style>

