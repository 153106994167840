export default {
    getSpinner(state) {
        return state.spinner;
    },
    getMenu(state) {
        return state.menu;
    },
    //prodotti dello scontrino
    getProdsReceipt(state) {
        return state.prodsReceipt;
    },
    getBills(state) {
        return state.bills;
    },
    getAlertReceipt(state){
        return state.showAlertReceipt;
    },
    //totale scontrino
    getTotal(state) {
        return state.total;
    },
    //elenco prodotti
    getProducts: (state) => {
        return state.products;
    },
    getIDCustomer: (state) => {
        return state.idCustomer;
    },
    getCustomer: (state) => {
        return state.customer;
    },
    getCustomers: (state) => {
        return state.customers;
    },
    getTransfers: (state) => {
        return state.transfers;
    },
    getSales: (state) => {
        return state.sales;
    },
    getIdSale: (state) => {
        return state.idSale;
    },
    getTotalSales: (state) => {
        return state.totalsales;
    },
    //elenco categorie
    getCategories: (state) => {
        return state.categories;
    },
    getIdCategory: (state) => {
        return state.idCategory;
    },
    getChange(state) {
        return state.change;
    },
    getExChange(state) {
        return state.exchange;
    },
    getSearchString(state) {
        return state.searchstring;
    },
    getSearchSale(state) {
        return state.searchsale;
    },
    getDeviceConn(state) {
        return state.deviceconn;
    },
    getSellsAlert(state) {
        return state.sellsAlert;
    },
    getProdsChange: (state) => {
        return state.prodsChange;
    },
    getDocHeading: (state) => {
        return state.docHeading;
    },
    getInvoice:(state) => {
        return state.invoice;
    },
}