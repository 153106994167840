<template>
  <div class="modal" :class="{show : open}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Dettaglio vendita</h5>
          <button v-on:click="toggleModal()" type="button" class="btn-close"/>
        </div>
        <div class="modal-body">
          <div class="receipt-top"></div>
          <div class="receipt">
            <div class="text-center title-receipt">{{workspace.ragsoc}}</div>
            <div class="text-center">{{workspace.name}}</div>
            <div class="text-center subtitle">{{workspace.street}} {{workspace.streetnumber}}</div>
            <div class="text-center subtitle">{{workspace.zipcode}}, {{workspace.city}} ({{workspace.prov}})</div>
            <div class="text-center subtitle">P.IVA {{workspace.piva}}</div>
            <table>
              <tr>
                <td class="text-start"></td>
                <td class="text-end">EURO</td>
              </tr>
              <tr v-for="prod in sale.products">
                <td class="text-start" v-if="prod.qnt > 1">x {{prod.qnt}} {{prod.name}}</td>
                <td class="text-start" v-else>{{prod.name}}</td>
                <td class="text-end">{{formatPrice(prod.price)}}</td>
              </tr>
              <tr v-if="sale.total < 0">
                <td class="text-start">BUONO SCONTO</td>
                <td class="text-end">{{formatPrice(sale.total * -1)}}</td>
              </tr>
              <tr v-if="sale.discount != 0">
                <td class="text-start">SCONTO</td>
                <td class="text-end">-{{formatPrice(sale.discount)}}</td>
              </tr>
              <tr>
                <td class="text-start"><h5 class="total">TOTALE EURO</h5></td>
                <td class="text-end"><h5 class="total">{{formatPrice(sale.total)}}</h5></td>
              </tr>
              <tr>
                <td class="text-start">{{sale.payment.toUpperCase()}}</td>
                <td v-if="sale.change" class="text-end">{{formatPrice(parseFloat(sale.total) + parseFloat(sale.change))}}</td>
                <td v-else class="text-end">{{sale.total}}</td>
              </tr>
              <tr>
                <td class="text-start">RESTO</td>
                <td v-if="sale.change" class="text-end">{{formatPrice(sale.change)}}</td>
                <td v-else class="text-end">0,00</td>
              </tr>
            </table>
            <table>
              <tr>
                <td class="text-start">{{sale.localtime}} - {{sale.timestamp}}</td>
                <td class="text-end">SF: {{sale.zRepNumber}}-{{sale.number}}</td>
              </tr>
            </table>
          </div>
          <div class="receipt-bottom"></div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-dark" type="button" v-on:click="toggleModal()">Indietro</button>
        </div>
      </div>
    </div>
  </div>
  <button class="btn btn-sm btn-success mb-2" v-on:click="toggleModal()">
    Vedi
  </button>
</template>

<script>

export default {
  name: "ShowReceipt",
  props: {
    visible: Boolean,
    sale: Object
  },
  data() {
    return {
      open: this.visible,
      workspace : null
    }
  },
  beforeMount() {
    this.workspace = JSON.parse(localStorage.getItem("workspace"));
  },
  methods: {
    toggleModal() {
      this.open = !this.open
    },
    formatPrice(value) {
      return Number(value)
          .toFixed(2)
          .replace(".", ",");
    },
  },
}
</script>

<style scoped>
.modal-dialog {
  background-color: white;
  z-index: 1;
}

.modal:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

.receipt{
  width: 100%;
  padding: 15px 25px 15px 25px;
  font-family: Roboto;
  color: #000;
  margin: 0 auto;
  max-width: 370px;
  overflow: hidden;
  background-image: url(../../public/images/receipt_body.png);
  background-size: 100%;
  background-repeat: repeat-y;
}

.receipt .subtitle{
  font-size: 13px;
}

.receipt .total{
  line-height: 20px !important;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.receipt-top, .receipt-bottom{
  width: 100%;
  margin: 0 auto;
  max-width: 370px;
  height: 17px;
  background-size: 100% !important;
}

.receipt-top{
  background-image: url(../../public/images/receipt-top.png);
  background-repeat: no-repeat;
}

.receipt-bottom{
  background-image: url(../../public/images/receipt_bottom.png);
  background-repeat: no-repeat;
}

.title-receipt{
  font-size: 20px;
}

.receipt table{
  margin-top: 20px;
  width: 100%;
  border: none;
  font-weight: normal;
}

.receipt table tr td, .receipt table tr th{
  line-height: 20px !important;
  border: none;
  padding: 0;
}
</style>

