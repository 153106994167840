<template>
  <div class="modal" :class="{show : open}">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Dati fattura</h5>
          <button @click="toggleModal()" type="button" class="btn-close"/>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" v-if="this.errors.length" v-for="error in this.errors">
            {{error}}
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <input type="text" placeholder="Ragione sociale" v-model="docHeading.company" class="form-control mb-2">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input type="text" placeholder="Partita IVA" v-model="docHeading.vatid" class="form-control mb-2">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input type="text" placeholder="PEC" v-model="docHeading.pec" class="form-control mb-2">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input type="text" placeholder="Codie SDI" v-model="docHeading.sdicode" class="form-control mb-2">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input type="text" placeholder="Indirizzo" v-model="docHeading.address" class="form-control mb-2">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <input type="text" placeholder="CAP" maxlength="5" v-model="docHeading.zipcode" class="form-control mb-2">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <input type="text" placeholder="Prov" maxlength="2" v-model="docHeading.prov" class="form-control mb-2">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input type="text" placeholder="Città" v-model="docHeading.city" class="form-control mb-2">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input type="text" placeholder="Stato" v-model="docHeading.country" class="form-control mb-2">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-success" v-on:click="showReceiptAlert()">Procedi</button>
        </div>
      </div>
    </div>
  </div>
  <button v-on:click="toggleModal()" :disabled="total < 0" class="scontrino float-none btn btn-large btn-dark">
    Fattura
  </button>
</template>

<script>
import ReceiptAlert from "@/components/ReceiptAlert.vue";

export default {
  name: "InvoiceAlert",
  components: {ReceiptAlert},
  data() {
    return {
      errors : [],
      open: false,
    }
  },
  computed: {
    docHeading() {
      return this.$store.getters.getDocHeading;
    },
    total() {
      return this.$store.getters.getTotal;
    },
  },
  methods: {
    toggleModal() {
      this.open = !this.open;
      this.$store.commit('setReceiptAlert', false);
    },
    showReceiptAlert(){
      //check fields
      if(this.docHeading.company &&
          this.docHeading.vatid &&
          (this.docHeading.sdicode || this.docHeading.pec) &&
          this.docHeading.address &&
          this.docHeading.city &&
          this.docHeading.country &&
          this.docHeading.zipcode &&
          this.docHeading.prov){
        this.open = false;
        this.$store.commit('setInvoice', true);
        this.$store.commit('setReceiptAlert', true);
      }else{
        this.errors.push("Attenzione ci sono campi vuoti!");
      }
    }
  }
}
</script>

<style scoped>

.modal-dialog {
  background-color: white;
  z-index: 1;

}

.modal:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

.scontrino {
  width: 100%;
  border-radius: 0px;
  margin-left: 0px;
  margin-right: 2px;
}


label, h4 {
  float: left;
}

input, button {
  border-radius: 0px;
}
</style>