<template>
  <div class="cont-scontrino">
    <div class="input-group">
      <select class="form-control form-control-sm" v-model="indexbill" v-on:change="changeReceipt()">
        <option value="new">Nuovo conto</option>
        <template v-if="bills.length" v-for="(bill, index) in bills">
          <option :value="index">{{bill.name}}</option>
        </template>
      </select>
      <div class="input-group-append">
        <button class="btn btn-danger btn-sm" v-on:click="deleteBill()">Elimina</button>
        <button class="btn btn-dark btn-sm" v-on:click="saveBill()">Salva</button>
      </div>
    </div>
    <table class="table table-striped" id="scontrino">
      <thead>
        <th></th>
        <th class="text-start">Prodotto</th>
        <th class="text-center">Qnt</th>
        <th class="text-end">Prezzo</th>
      </thead>
      <tbody>
      <tr v-if="prodsReceipt.length > 0" v-for="(prodotto, index) in prodsReceipt" :key="prodotto.id">
        <!--<td>
          <img v-if="prodotto.image" class="img-product" :src="prodotto.image" alt=""/>
          <img v-else class="img-product" :src="'images/placeholder.jpg'" alt=""/>
        </td>-->
        <td>
          <button v-if="!exchange" class="btn btn-sm btn-danger" v-on:click="deleteItemReceipt(index)">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
            </svg>
          </button>
        </td>
        <td class="text-start">
          <template v-if="prodotto.type !== 'multivariant'">
            <p class="product">{{ prodotto.description }}</p>
          </template>
          <template v-else>
            <p class="product">{{ prodotto.description || prodotto.variant.variantname }}</p>
          </template>
        </td>
        <td class="text-center">
          <div class="input-group">
            <button v-on:click="changeQnt(index,'-')" class="btn  btn-dark btn-qnt">-</button>
            <input readonly v-model="prodotto.qnt" class="form-control form-control-sm input-qnt">
            <button v-on:click="changeQnt(index,'+')" class="btn  btn-dark btn-qnt">+</button>
          </div>
        </td>
        <td>
          <p class="price text-end">{{
              prodotto.discountprice ? formatPrice(prodotto.discountprice * prodotto.qnt) : formatPrice(prodotto.price * prodotto.qnt)
            }} €</p>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="row">
    <div class="col-6 text-start">
      <b>Pezzi totali</b>
    </div>
    <div class="col-6 text-end">
      <b>{{totalQnt}}</b>
    </div>
  </div>
  <div class="row ">
    <div class="col-5">
      <h3 class="float-start">TOTALE</h3>
    </div>
    <div class="col-7">
      <h3 class="float-end"> {{ formatPrice(this.total) || 0 }} €</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 mb-2">
      <div class="d-flex justify-content-between customer-block" v-if="customer">
        <div>{{customer?.name}} {{customer?.surname}}</div>
        <button class="btn btn-sm btn-danger" v-on:click="removeCustomer()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
          </svg>
        </button>
      </div>
      <div class="customer-block" v-else>
        Nessun cliente selezionato
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4 mb-2">
      <CustomersAlert/>
    </div>
    <div class="col-sm-4 mb-2">
      <InvoiceAlert/>
    </div>
    <div class="col-sm-4 mb-2">
      <ReceiptAlert/>
    </div>
  </div>
  <button type="button" class="delete float-end btn btn-large btn-danger" @click="emptyReceipt()">Cancella</button>
</template>

<script>
import ReceiptAlert from "@/components/ReceiptAlert";
import SellsAlert from "@/components/SellsAlert.vue";
import InvoiceAlert from "@/components/InvoiceAlert.vue";
import {BASE_URL} from "@/constants/constants";
import CustomersAlert from "@/components/CustomersAlert.vue";


export default {
  name: "Scontrino",
  components: {
    CustomersAlert,
    InvoiceAlert,
    SellsAlert,
    ReceiptAlert
  },
  data() {
    return {
      indexbill : "new"
    };
  },
  computed: {
    prodsReceipt() {
      return this.$store.getters.getProdsReceipt;
    },
    total() {
      return this.$store.getters.getTotal;
    },
    bills() {
      return this.$store.getters.getBills;
    },
    customer(){
      return this.$store.getters.getCustomer;
    },
    totalQnt(){
      return this.prodsReceipt.reduce((accumulator, currentValue) => accumulator + currentValue.qnt, 0);
    },
    exchange(){
      return this.$store.getters.getExChange;
    }
  },
  methods: {
    deleteBill(){
      this.$store.commit('removeBill', this.indexbill);
      this.$store.commit('emptyReceipt');
      this.indexbill = "new";//rimetto la selezione su nuovo conto
    },
    saveBill() {
      if(this.prodsReceipt.length && this.indexbill === "new") {
        this.$store.commit('addBill', this.bills.length + 1);
      }else{
        alert("Attenzione: lo scontrino è vuoto!");
      }
    },
    changeReceipt(){
      if(this.indexbill !== "new") {
        this.$store.commit('setProdsReceipt', this.bills[this.indexbill].receipt);
        this.$store.dispatch('calculateTotal');
      }else{
        this.$store.commit('emptyReceipt');
      }
    },
    emptyReceipt() {
      this.$store.commit('emptyReceipt');
      this.$store.commit('setChange', 0);
      this.$store.commit('setProdsChange', []);
      this.$store.commit('setDocHeading', {});
      this.$store.commit('setInvoice', false);
      this.$store.commit('setExChange', false);//quando faccio un cambio ma cancello rimetto la variabile a false
      let xml = '<printerCommand><clearText operator="1" /><resetPrinter operator="1" /></printerCommand>';
      this.$store.dispatch("sendData", {xml: xml, async: true});
    },
    deleteItemReceipt(index){
      this.$store.commit('delItemReceipt',index);
      this.$store.dispatch('calculateTotal');
    },
    formatPrice(value) {
      return parseFloat(value)
          .toFixed(2)
          .replace(".", ",");
    },
    changeQnt(index, action) {
      this.$store.dispatch("setQntReceipt", {index, action});
    },
    removeCustomer(){
      this.$store.commit("setIDCustomer" , null);
      this.$store.commit("setCustomer" , null);
    }
  }
}
</script>

<style scoped>
#scontrino {
  width: 100%;
  border-radius: 0px;
}

#scontrino .input-group {
  width: 100px;
}

.input-qnt {
  width: 20px !important;
  text-align: center;
}

.img-product {
  max-width: 50px;
}

.btn-qnt {
  text-align: center;
  width: 30px;
  height: 30px;
  font-size: 10px;
}

table th {
  padding: 8px;
}

thead {
  border-bottom: 1px #d2d2d2 solid;
}

.price {
  text-align: start;
  font-size: 12px;
  font-weight: 600;
}

.product {
  text-align: start;
  font-size: 14px;
}

.cont-scontrino {
  border: 1px solid #d2d2d2;
  margin-top: 10px;
  position: relative;
  max-width: fit-content;
  overflow-y: scroll;
  height: 437px;
  margin-bottom: 3px;
  min-width: 100%;
}

.cont-scontrino select{
  border: none;
  border-bottom: 1px solid #dedede;
}

.delete {
  width: 100%;
  border-radius: 0px;
}

.customer-block{
  border: 1px solid #dedede;
  padding: 8px;
  line-height: 30px;
}
</style>