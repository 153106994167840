import Vuex from "vuex";
import getters from '@/services/getters';
import mutations from '@/services/mutations';
import actions from '@/services/actions';
export default new Vuex.Store({
    state: {
        //invoice ti dice se stai facendo una fattura se è a true è una fattura altrimenti è uno scontrino normale
        invoice: false,
        idCustomer : null,//id del cliente da associare allo scontrino
        customers : [],
        docHeading: {
            company: null,
            vatid: null,
            pec: null,
            address: null,
            city: null,
            country: null,
            zipcode: null,
            prov: null,
            sdicode: null,
        },
        message: "",
        showAlertStock: false,
        showAlertReceipt : false,
        //spinner generale
        spinner: true,
        menu: false,
        total: 0,
        change: 0,//resto
        exchange: false,//indicia il cambio merce
        products: [],
        categories: [],
        bills : [],//per salvare il conto
        prodsReceipt: [],//array dei prodotti nello scontrino
        prodsChange : [],//array dei prodotti da cambiare
        idCategory: null,//filtro per i prodotti
        transfers: [],
        sales: [],
        //prodotti nel refund
        prodsRefund: [],
        //in base al tipo di operazione chiamo l'endpoint dedicato
        refund: false,
        //lo devo passare al endpoint doRefund
        idSale: null,
        toggle: false,
        socket : null,//socket dello scanner
        sockconn : false, //vedo se la socket è connessa o no,
        deviceconn : false, //vedo se il device è connesso o no
        searchstring : "", //lettura da scanner o ricerca prodotto,
        searchsale : "",
        sellsAlert : false //variabile di stato per fare aprire le vendite
    },
    getters,
    mutations,
    actions,
    modules: {}
});
