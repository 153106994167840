<template>
  <div class="modal" :class="{show : statusAlert}">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Vendite</h5>
          <button @click="toggleSales" type="button" class="btn-close"/>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <form @submit.prevent="getSales">
                <div class="input-group mb-3">
                  <input type="text" class="carcaInput form-control" placeholder="Cerca vendite con barcode" v-model="searchsale">
                  <div class="input-group-append">
                    <button class="btn btn-danger" type="button" @click="deleteSearch()">
                      Elimina
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button class="cerca btn btn-dark" type="submit">Cerca</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="sales" v-if="sales.length">
                <div class="table-responsive">
                  <table class="table table-light">
                    <thead>
                      <th>Numero</th>
                      <th>Data</th>
                      <th>Pagamento</th>
                      <th>Sconto</th>
                      <th>Totale</th>
                      <th>Resto</th>
                      <th>Stato</th>
                      <th>Azioni</th>
                    </thead>
                    <tbody>
                      <template v-for="sale in sales">
                        <tr>
                          <td>
                            <template v-if="sale.type === 'invoice'">
                                <span class="badge bg-dark">Fattura</span>
                            </template>
                            <template v-else>
                              {{ sale.zRepNumber }}/{{ sale.number }}
                            </template>
                          </td>
                          <td>
                            {{sale.localtime}}
                          </td>
                          <td>
                            {{sale.payment.toUpperCase()}}
                          </td>
                          <td>
                            {{this.formatPrice(sale.discount)}} €
                          </td>
                          <td>
                            {{this.formatPrice(sale.total)}} €
                          </td>
                          <td>
                            {{this.formatPrice(sale.change)}} €
                          </td>
                          <td>
                            <template v-if="sale.status === 'sold'">
                              <span class="badge bg-success">Eseguito</span>
                            </template>
                            <template v-if="sale.status === 'changed'">
                              <span class="badge bg-warning">Cambiato</span>
                            </template>
                            <template v-if="sale.status === 'refunded'">
                              <span class="badge bg-danger">Reso</span>
                            </template>
                            <template v-if="sale.status === 'deleted'">
                              <span class="badge bg-danger">Stornato</span>
                            </template>
                          </td>
                          <td class="actions">
                            <template v-if="sale.status === 'sold'">
                              <!--
                              <template v-if="sale.status !== 'deleted'">
                              <div class="btn-group show">
                                <button class="btn btn-sm btn-dark dropdown-toggle" v-on:click="toggleItem(sale.id)">Opzioni</button>
                                <div class="dropdown-menu dropdown-menu-right" :id="sale.id">
                                  <a class="dropdown-item" href="#" @click="handleClick('cortesia', sale)">
                                    Cortesia
                                  </a>
                                  <a class="dropdown-item" href="#" @click="handleClick('ristampa',sale)">
                                    Ristampa
                                  </a>
                                  <RefundAlert :visible="false" :prodSell="{sale}"/>
                                  <a class="dropdown-item bg-danger text-white" href="#" @click="handleClick('storno', sale)">
                                    Storna
                                  </a>
                                </div>
                              </div>
                            </template>-->
                              <ShowReceipt :visible="false" :sale="sale"/>
                              <button class="btn btn-sm btn-dark mb-2" type="button" @click="handleClick('cortesia', sale)">
                                Cortesia
                              </button>
                              <button class="btn btn-sm btn-dark mb-2" type="button" @click="handleClick('ristampa',sale)">
                                Ristampa
                              </button>
                              <RefundAlert :visible="false" :sale="sale"/>
                              <button class="btn btn-sm btn-danger mb-2" type="button" @click="handleClick('storno', sale)">
                                Storna
                              </button>
                            </template>
                            <template v-else>
                              -
                            </template>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else class="alert alert-info text-center">
                Non ci sono vendite
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="d-flex w-100 justify-content-between">
            <p>
              Totale vendite: <strong>{{formatPrice(totalsales || 0)}} €</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button v-on:click="toggleSales()" class="btn btn-large btn-success">Vendite</button>
</template>

<script>
import ShowReceipt from "@/components/ShowReceipt";
import RefundAlert from "@/components/RefundAlert";

export default {
  name: "SellsAlert",
  components: {
    RefundAlert,
    ShowReceipt
  },
  data() {
    return {}
  },
  computed: {
    sales() {
      return this.$store.getters.getSales;
    },
    totalsales(){
      return this.$store.getters.getTotalSales;
    },
    searchsale : {
      get(){
        return this.$store.getters.getSearchSale;
      },
      set(value){
        this.$store.commit("setSearchSale",value);
      }
    },
    statusAlert(){
      return this.$store.getters.getSellsAlert;
    }
  },
  methods: {
    deleteSearch() {
      this.$store.commit("setSearchSale","");
      this.$store.dispatch("getSales");
    },
    getSales() {
      this.$store.dispatch("getSales");
    },
    toggleSales() {
      if(!this.statusAlert){
        this.getSales();
      }
      this.$store.commit("setSellsAlert", !this.statusAlert);
    },
    handleClick(buttonId, sell) {
      switch (buttonId) {
        case "ristampa": {
          let date = sell.localtime.split(" ");
          let divDate = date[0].split("/");
          //login
          let data_to_send = `<printerCommand>
                                  <directIO command="4038" data="0212345                                                                                                    " comment="Login password 12345 followed by 95 spaces for a length of 100" />
                              </printerCommand>`;
          this.$store.dispatch("sendData", {xml : data_to_send, async : true});
          data_to_send = `<printerCommand>
                              <printContentByNumbers operator="1" dataType="1" day="${divDate[0].replaceAll("0", "")}"
                              month="${divDate[1].replaceAll("0", "")}" year="${divDate[2]}" fromNumber="${sell.number}"
                              toNumber="${sell.number}" />
                          </printerCommand>`;
          this.$store.dispatch("sendData", {xml : data_to_send, async : true});
        }break;

        case "storno": {
          if(confirm("Attenzione: lo scontrino selezionato sarà annullato. Continuare?")) {
            let Date = sell.localtime.split(" ");
            let fulldate = Date[0].replaceAll("/", "");
            let dataSend = `<printerFiscalReceipt>
                                <printRecMessage operator="1" message="VOID ${sell.zRepNumber} ${sell.number} ${fulldate} ${window.localStorage.getItem("idCashierLMG")}" messageType="4" />
                            </printerFiscalReceipt>`;

            this.$store.dispatch("sendData", {xml : dataSend, async : true, type : "storno"});
            this.$store.dispatch('refundSale', sell.id);
            this.$store.dispatch("getSales");
            //ritorno nello stato a 0
            let xml = '<printerCommand><clearText operator="1" /><resetPrinter operator="1" /></printerCommand>';
            this.$store.dispatch("sendData", {xml : xml, async : true});
          }
        }break;

        case "cortesia": {
          //stampo scontrino di cortesia
          let send_courtesy = `<printerNonFiscal><beginNonFiscal operator="1" />`;
          for (let i = 0; i < sell.products.length; i++) {
            send_courtesy += `<printNormal operator="1" font="1" data="${sell.products[i].qnt} X ${sell.products[i].name.toUpperCase()}" />`;
          }
          send_courtesy += `<printNormal operator="1" font="1" data="**********************************************" />
                            <printNormal operator="1" font="1" data="Documento RT N. ${sell.zRepNumber} - ${sell.number}" />
                            <printNormal operator="1" font="1" data="Del: ${sell.localtime}" />
                            <printNormal operator="1" font="1" data="Matricola: ${sell.idCashier}" />
                            <printNormal operator="1" font="1" data="**********************************************" />
                            <printNormal operator="1" font="1" data="" />
                            <printBarCode operator="1" position="901" width="2" height="66" hRIPosition="3" hRIFont="C" codeType="CODE39" code="${sell.timestamp}" />
                            <printNormal operator="1" font="1" data="" />
                            <endNonFiscal operator="1" /></printerNonFiscal>`;
          this.$store.dispatch("sendData", {xml : send_courtesy, async : true});
        }break;
        default:
          break;
      }
    },
    formatPrice(value) {
      return Number(value)
          .toFixed(2)
          .replace(".", ",");
    },
  }
}
</script>

<style scoped>
.modal-dialog {
  z-index: 1;
}

.modal:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

table{
  text-align: center;
  font-weight: bold;
}

.sales{
  width: 100%;
  overflow: auto;
  max-height: 400px;
}

.receiptDet td{
  font-weight: normal;
  background-color: #ffffff;
}

.actions .btn{
  display: inline-block !important;
}

</style>