<template>
  <div class="modal" :class="{show : open}">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Clienti</h5>
          <button @click="toggleTransfers" type="button" class="btn-close"/>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <form @submit.prevent="getTransfers">
                <div class="input-group mb-3">
                  <input type="text" class="carcaInput form-control" placeholder="Cerca cliente" v-model="search">
                  <div class="input-group-append">
                    <button class="btn btn-danger" type="button" @click="deleteSearch">
                      Elimina
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button class="cerca btn btn-dark" type="submit">Cerca</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="transfers" v-if="search && customers.length">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <th>ID</th>
                      <th>Nome</th>
                      <th>Cognome</th>
                      <th>E-mail</th>
                      <th>Telefono</th>
                      <th>Città</th>
                      <th>Azioni</th>
                    </thead>
                    <tbody>
                      <tr v-for="cust in customers">
                        <td>
                          {{cust.iduser}}
                        </td>
                        <td>
                          {{cust.name}}
                        </td>
                        <td>
                          {{cust.surname}}
                        </td>
                        <td>
                          {{cust.email}}
                        </td>
                        <td>
                          {{cust.phone}}
                        </td>
                        <td>
                          {{cust.city}} <span v-if="cust.prov">({{cust.prov}})</span>
                        </td>
                        <td class="actions">
                          <button class="btn btn-sm btn-dark mb-2" type="button" v-on:click="selectCustomer(cust)">
                            Seleziona
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button @click="toggleTransfers" class="scontrino float-start btn btn-large btn-dark">Clienti</button>
</template>

<script>
export default {
  name: "CustomersAlert",
  data() {
    return {
      open: false,
      search : ""
    }
  },
  computed: {
    customers() {
      return this.$store.getters.getCustomers;
    }
  },
  methods: {
    selectCustomer(customer){
      this.$store.commit("setIDCustomer" , customer.iduser);
      this.$store.commit("setCustomer" , customer);
      this.$store.commit("setCustomers" , []);
      this.search = "";
      this.toggleTransfers();
    },
    deleteSearch() {
      this.search = "";
      //this.$store.commit("setIDCustomer" , null);
      //this.$store.dispatch("getCustomers");
      this.$store.commit("setCustomers" , []);
    },
    getTransfers() {
      this.$store.dispatch("getCustomers", {"search": this.search})
    },
    toggleTransfers() {
      this.open = !this.open;
    },
  }
}
</script>

<style scoped>
.modal-dialog {
  background-color: white;
  z-index: 1;

}

.modal:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

.scontrino {
  width: 100%;
  border-radius: 0px;
  margin-left: 0px;
  margin-right: 2px;
}


label, h4 {
  float: left;
}

input, button {
  border-radius: 0;
}
</style>