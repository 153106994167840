<template>
  <div class="container">
    <div class="row" v-show="$store.state.showAlertStock">
      <div class="alert alert-danger alert-dismissible">
        <strong>Attenzione</strong> {{ $store.state.message }}
        <button v-on:click="$store.state.showAlertStock = false" type="button" class="btn-close"></button>
      </div>
    </div>
    <div class="row block-cat mb-3">
      <template v-if="categories.length">
        <div class="col-xl-3 col-lg-4 col-md-6 mb-3" v-for="category in categories" :key="category.id">
          <button type="button" class="btn btn-sm btn-category mb-2" :class="{'btn-dark' : (idCategory === category.id), 'btn-outline-dark' : (idCategory !== category.id)}" @click="getProducts(category.id)">
            <!--<img v-if="category.imageUrl" class="img-cat" :src="base + '/' + category.imageUrl">
            <img v-else class="img-cat" :src="'images/placeholder.jpg'" alt="placeholder category">
            <br>-->
            {{ category.description }}
          </button>
        </div>
      </template>
      <template v-else>
        <div class="col-lg-12">
          <div class="alert alert-info mb-0">Non ci sono categorie inserite</div>
        </div>
      </template>
    </div>
    <div class="row  block-prods">
      <template v-if="products.length">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3" v-for="product in products" :key="product.id">
          <div class="card product-card" @click="(product.type === 'multivariant') ? showProductDetails(product) : addProdReceipt(product)">
            <div class="card-body  product-card-body">
              <img v-if="product.image" class="img-product" :src="product.image" alt=""/>
              <img v-else class="img-product" :src="'images/placeholder.jpg'" alt=""/>
              <h5 class="card-title" v-if="product.description">{{ product.description }}</h5>
              <h5 class="card-title" v-else>{{ product.name }}</h5>
              <p class="card-prezzo">{{product.discountprice ? formatPrice(product.discountprice) : formatPrice(product.price) }} €</p>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="col-lg-12">
          <div class="alert alert-info mb-0">Non ci sono prodotti inseriti</div>
        </div>
      </template>
    </div>
    <!-- details prod dialog-->
    <div class="modal" v-if="this.product" :class="{show: showModalDetails}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Dettaglio prodotto</h5>
            <button v-on:click="showModalDetails = false" type="button" class="btn-close"/>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <img v-if="product.image" class="img-product" :src="product.image" alt=""/>
                  <img v-else class="img-product" :src="'images/placeholder.jpg'" alt=""/>
                </div>
                <div class="col-lg-6">
                  <p>{{ product.description }}</p>
                  <p>{{ formatPrice(product.price) }} €</p>
                  <button type="button" v-if="this.product.type === 'multivariant'" class="btn btn-light btn-calc"
                          :key="variant.id" v-for="variant in product.variants" v-on:click="addProdReceipt(product, variant)">
                    <!--prendo solo la lettera finale per le taglie-->
                    {{ variant.description.split('-').pop() }}<br>
                    Giacenze: {{ variant.stock}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BASE_URL} from "@/constants/constants";

export default {
  data() {
    return {
      showModalDetails: false,
      product: null,
      //showAlertStock: false,
      max_item_desc_len: 17,
      base : BASE_URL
    };
  },
  methods: {
    //aggiunge allo scontrino il prodotto semplice
    addProdReceipt(prodotto, variant = null) {
      //console.log("prodotto", prodotto.subproducts[0].stock);
      //console.log("prodotto", prodotto.variants[0].stock);
      let prodname = variant ? variant.description : prodotto.description;
      let price = prodotto.discountprice || prodotto.price;
      this.showModalDetails = false;

      //controllo delle stocks per i compound
      let stocksComp;
      if(prodotto.subproducts){
        stocksComp = this.findMinStockSub(prodotto.subproducts);
      }
      if (prodname.length < this.max_item_desc_len) {
        //forzare la lunghezza di max_item_desc_len mettendo i spazi se necessario
        prodname = prodname + "                 "; // lunghezza massima della stampante è 17 caratteri senza la quantità
        prodname = prodname.substring(0, this.max_item_desc_len);
      } else {
        prodname = prodname.slice(0, this.max_item_desc_len); // Troncare la stringa
      }

      if (variant && variant.stock <= 0 || prodotto.stock <= 0 || stocksComp <= 0) {
        this.$store.commit('setShowAlertStock',true);
        this.$store.commit('setMessage',"Il prodotto non ha disponibilità a magazzino.");
      } else {
        this.$store.commit('setShowAlertStock', false);
      }
        //stock per varianti e prodotti semplici
        let stock;
        if(variant){
          //se ha sub
          stock = variant.stock;
        }else if(prodotto.subproducts){
          stock = this.findMinStockSub(prodotto.subproducts);
        }else{
          stock = prodotto.stock;
        }
        //console.log("idvariante",variant)

        //aggiuge i prodotti nello scontrino
        this.$store.dispatch('addProduct', {
          id: prodotto.id,
          idvariant: variant ? variant.id : null,
          idcategory: prodotto.idcategory,
          description: variant ? variant.description : prodotto.description,
          price: prodotto.discountprice || prodotto.price,
          tax: prodotto.tax,
          type: prodotto.type,
          qnt: 1,
          stock: stock || stocksComp,
          image: prodotto.image
        });
        this.$store.dispatch("calculateTotal");
        //prendo la quantità totale del prodotto che c'é
        let receipt = this.$store.getters.getProdsReceipt;
        let prodFiltered = receipt.filter(prod => prod.id === prodotto.id);
        //stampa su display il nome e il prezzo del prodotto
        let secondLine = `${this.formatPrice(price * prodFiltered[0].qnt)} EURO`;
        let strpad = this.addPadString(secondLine,20);
        let messageDisplay = `<printerCommand><displayText operator="1" data="x${prodFiltered[0].qnt} ${prodname}${strpad}${secondLine}" /></printerCommand>`;
        this.$store.dispatch("sendData", {xml : messageDisplay, hidespinner : true});//metto a true perché non mi devo aspettare results dalla stampante

    },
    findMinStockSub(subproducts){
      let min;
      if(subproducts.length !== 0){
        min = subproducts.stock;
        for(let i = 0; i < subproducts.length; i++){
          if(subproducts[i].stock < min){
            min = subproducts[i].stock;
          }
        }
        console.log("min",min);
        return min;
      }
      /*
      //prendere stock delle varianti
      if(variant.stock){
         min = variant.stock;
        for(let i = 0; i < variant.length; i++){
          if(variant[i].stock < min){
            min = variant[i].stock;
          }
        }
      }else{
        //per prendere stock dei compound
        min = variant.stock;
        for(let i = 0; i < variant.length; i++){
          if(variant.stock < min){
            min = variant.stock;
          }
        }
      }
      console.log("min",min);
      return min;
       */
    },
    addPadString(string, numtotalpad){
      let strpad = "";
      if(string.length < 20){
        for(let i = 0; i < numtotalpad - string.length; i++){
          strpad += " ";
        }
        return strpad;
      }else{
        return "";
      }
    },
    //prende il prodotto per vederne i dettagli nella modale
    showProductDetails(product) {
      this.product = product;
      this.showModalDetails = true;
    },
    //mostra i prodotti e se è presente la categoria mostra i prodotti di quella categoria
    getProducts(idcategory) {
      this.$store.commit('setIdCategory',idcategory);
      this.$store.dispatch("getProducts", {"idcategory" : idcategory});
    },
    formatPrice(value) {
      return Number(value)
          .toFixed(2)
          .replace(".", ",");
    },
  },
  computed: {
    products() {
      return this.$store.getters.getProducts
    },
    categories() {
      return this.$store.getters.getCategories;
    },
    idCategory() {
      return this.$store.getters.getIdCategory;
    }
  },
  beforeMount() {
    this.$store.dispatch('getCategories');
  }
};
</script>

<style scoped>
.product-card {
  border-radius: 0;
  margin-bottom: 20px;
  height: 250px;
  display: flex;
  flex-direction: column;
}

.product-card-body {
  font-size: 18px;
  font-weight: bold;
  height: 250px;
  overflow: hidden;
  /*
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
   */
}

.row{
  max-height: fit-content;
}
.block-cat, .block-prods{
  padding: 20px 10px 20px 10px;
  border: 1px solid #dedede;
  overflow-y: scroll;
}

.block-prods{
  height: 426px;
}

.block-cat{
  height: 150px;
}

.btn-category{
  text-align: center;
  display: block;
  width: 100%;
  padding: 10px 10px;
  //height: 200px;
}

.modal-dialog {
  background-color: white;
  z-index: 1;
}

.modal:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

.img-product {
  width: 100%;
  background-size: 60%;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}

.img-product-details {
  width: 100%;
  background-size: 60%;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}

.img-product:hover {
  cursor: pointer;
}

.card-title {
  font-size: 15px;
}

.card {
  border-radius: 0;
  margin-bottom: 20px;
  height: fit-content;
}

.card-body {
  font-size: 18px;
  font-weight: bold;
  /*height: fit-content;*/
}

.btn-category:focus {
  background-color: #000;
  color: #ffffff;
}

.show{
  display: block;
}

.img-cat{
  width: 100px;
  display: block;
  margin: 0 auto;
}
</style>