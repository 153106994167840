export default{
    setSpinner(state, value){
        state.spinner = value;
    },
    setMenu(state, value){
        state.menu = value;
    },
    showModal(state) {
        state.toggle = true;
    },
    hideModal(state) {
        state.toggle = false;
    },
    setSales(state, payload) {
        state.sales = payload;
    },
    setTotalSales(state, payload) {
        state.totalsales = payload;
    },
    setIdSale(state, payload){
        state.idSale = payload;
    },
    setProducts(state, payload) {
        state.products = payload;
    },
    setCategories(state, payload) {
        state.categories = payload;
    },
    setIdCategory(state, payload) {
        state.idCategory = payload;
    },
    addBill(state, index) {
        state.bills.push({"name" : "Conto " + index, "receipt" : state.prodsReceipt});
    },
    removeBill(state, indexbill){
        state.bills.splice(indexbill, 1);
    },
    setProdsReceipt(state,receipt){
        state.prodsReceipt = receipt;
    },
    emptyReceipt(state) {
        state.prodsReceipt = [];
        state.total = 0;
        state.change = 0;
    },
    delItemReceipt(state,index){
        state.prodsReceipt.splice(index,1);
    },
    setExChange(state,payload){
        state.exchange = payload;//variabile cambio
    },
    setStatusRefund(state,payload){
        state.refund = payload;
    },
    setTotal(state, payload) {//totale scontrino
        state.total = payload;
    },
    setChange(state,payload){
        state.change = payload;//resto per contanti
    },
    setTransfers(state, payload) {
        state.transfers = payload;
    },
    setCustomer(state, payload) {
        state.customer = payload;
    },
    setCustomers(state, payload) {
        state.customers = payload;
    },
    setIDCustomer(state, payload) {
        state.idCustomer = payload;
    },
    setSearchString(state, payload){
        state.searchstring = payload;
    },
    setSearchSale(state, payload){
        state.searchsale = payload;
    },
    setDeviceConn(state, payload){
        state.deviceconn = payload;
    },
    setSellsAlert(state, payload){
        state.sellsAlert = payload;
    },
    setReceiptAlert(state, payload){
        state.showAlertReceipt = payload;
    },
    setProdsChange(state, payload){
        state.prodsChange = payload;
    },
    setShowAlertStock(state, payload){
        state.showAlertStock = payload;
    },
    setMessage(state, payload){
        state.message = payload;
    },
    setDocHeading(state, payload) {
        state.docHeading = payload;
    },
    setInvoice(state, payload) {
        state.invoice = payload;
    }
}